<script setup>
    import { reactive, ref } from 'vue'
    import { useForm } from 'vee-validate';
    import { useTourStore } from "@/store/tour"
    import { useToast } from "primevue/usetoast"
    import * as yup from 'yup'
    import MZBreadcrumb from '@/components-chillpainai/MZBreadcrumb.vue'

    const tourStore = useTourStore()
    const toast = useToast()

    const loading = ref(false)

    const { errors, useFieldModel } = useForm({
        validationSchema: yup.object({
            code: yup.string().required(),
            title: yup.string().required(),
            keywords: yup.string().required(),
            description: yup.string().required(),
        })
    })

    const model = reactive({
        code: useFieldModel('code'),
        title: useFieldModel('title'),
        keywords: useFieldModel('keywords'),
        description: useFieldModel('description'),
    })

    const create = ($router) => {
        loading.value = true

        tourStore.store({ code: model.code, title: model.title, keywords: model.keywords, description: model.description }).then(({ data }) => {
            if (data.error) {
                loading.value = false
                toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
                return
            }

            toast.add({severity:'success', summary: 'Success', detail: 'Create Successful.', life: 3000})

            $router.push({ path: `/tours/${data.tour.id}/edit` })
        
        }).catch((e) => {
            toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
        })
    }
</script>

<template>
    <MZBreadcrumb :items="[{ label: 'Tour' }, { label: 'Create' }]" />

    <br/>

    <div class="flex flex-wrap justify-content-center card-container">
        <div class="col-12 lg:col-6">
            <div class="card">
                <div class="card-body">
                    <div class="col-12">
                        <div class="p-fluid">
                            <h5 class="card-title">สร้างทัวร์</h5>
                            <h6 class="card-subtitle mb-2 text-muted"></h6>
                            <p class="card-text"></p>
                            
                            <div class="field">
                                <label for="code">CODE</label>
                                <InputText v-model="model.code" id="code" type="text" placeholder="XXXXXX" :class="errors.code ? 'p-invalid' : ''" />
                            </div>
                            <div class="field">
                                <label for="title">ชื่อ</label>
                                <InputText v-model="model.title" id="title" type="text" placeholder="ชิลไปไหนทราเวล" :class="errors.title ? 'p-invalid' : ''" />
                            </div>
                            <div class="field">
                                <label for="keywords">คีย์เวิร์ด</label>
                                <InputText v-model="model.keywords" id="keywords" type="text" placeholder="ทัวร์,ท่องเที่ยว,ทริป" :class="errors.keywords ? 'p-invalid' : ''"/>
                            </div>
                            <div class="field">
                                <label for="description">รายละเอียดย่อ</label>
                                <InputText v-model="model.description" id="description" type="text" placeholder="ชิลไปไหน เที่ยว 4 พัก 3" :class="errors.description ? 'p-invalid' : ''"/>
                            </div>

                            <br/><br/>

                            <Button
                                :disabled="loading"
                                label="สร้าง" 
                                :loading="loading"
                                @click="create($router)"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
